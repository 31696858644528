//file: index.js
//language: javascript (gatsby)
//Description: this is the page source code
//Happy Love EBook Guide

//date create : 02/04/2020

// this import library for gatsby and react
import React from "react"
import { Helmet } from "react-helmet"
import lazy from "@loadable/component"
import App from "./App"
import { FaWhatsapp } from "react-icons/fa"

//import App from "./Appbook"

const SectioOne = lazy(() => import("../components/section/secOne"))
const SectionTwo = lazy(() => import("../components/section/secTwo"))
const SectionThere = lazy(() => import("../components/section/secThere"))
const SectionFour = lazy(() => import("../components/section/secFour"))
const SectionFive = lazy(() => import("../components/section/secFive"))
const SectionSix = lazy(() => import("../components/section/secSix"))
const SectionSeven = lazy(() => import("../components/section/secSeven"))
const SectionEight = lazy(() => import("../components/section/secEight"))
const SectionNine = lazy(() => import("../components/section/secNine"))
const SectionGuarte = lazy(() => import("../components/section/guarte"))
const SectionFaq = lazy(() => import("../components/section/faq"))

// function class {Aplication}, for front page Pre Order Book HLG
class ApplicationEbook extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* this is title website */}
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>E-Book Happy Love Guide</title>
          </Helmet>
        </div>
        {/* end title website */}
        {/* this section countdown */}
        <App />
        {/* end section countdown */}

        <SectioOne></SectioOne>

        <SectionTwo></SectionTwo>

        <SectionThere></SectionThere>

        <SectionFour></SectionFour>

        <SectionFive></SectionFive>

        <SectionSix></SectionSix>

        <SectionSeven></SectionSeven>

        <SectionEight></SectionEight>

        <SectionNine></SectionNine>

        <SectionGuarte></SectionGuarte>

        <SectionFaq></SectionFaq>

        <a
          href="https://wa.link/98i6u4"
          target="_blank"
          without
          rel="noreferrer"
          className="float"
        >
          <div className="my-float">
            <FaWhatsapp /> &nbsp; Butuh Bantuan?
          </div>
        </a>
      </React.Fragment>
    )
  }
}
export default ApplicationEbook
