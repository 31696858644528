import React, { Component } from "react"
// import Countdown from "./Countdown"
import { Navbar, Container, Col } from "react-bootstrap"

class App extends Component {
  render() {
    return (
      <Navbar expand="lg" variant="light" fixed="top" className="purple">
        <Container fluid>
          <Col lg={12} xs={12}>
            <div className="text-App">
              <p>Festival Harbolnas 12.12 | <a href="https://trulav.id/12-12/" target="_blank">KLIK DISINI</a> untuk miliki buku ini dengan discount up to 65%</p>
            </div>
          </Col>
          {/* <Col lg={6} xs={12}>
            <div className="App">
              <Countdown date={`August 18, 2023 21:00:00`} />
            </div>
          </Col> */}
        </Container>
      </Navbar>
    )
  }
}

export default App
